<template>
    <div class="relative hero" :class="content.cssClass" :style="`background-image: url(` + backgroundImage + `);`">
      <!-- TODO: Need to come back and figure out how to make background videos work with this again -->
      <div class="hero-container">
        <div>
          <img v-if="lockupImage" :src="lockupImage" :alt="content.label">
          <h3 v-else-if="content.heading" v-text="content.heading" />
          <AppLink v-if="content.cta && content.cta[0]" :content="content.cta[0]" class="button pulse text-center text-xs md:text-small"/>
        </div>
        <div class="images">
          <img v-if="content.mainImage && content.mainImage.fields.file.url" :src="content.mainImage.fields.file.url" :alt="content.heading">
          <img class="logo" v-if="content.logo && content.logo.fields.file.url" :src="content.logo.fields.file.url" :alt="content.logo.fields.title">
        </div>
      </div>
    </div>
</template>

<script>
import AppLink from '~/components/common/AppLink'

export default {
  components: {
    AppLink
  },
  props: [
    'content'
  ],
  computed: {
    isMobile() {
      return this.$mq === 'sm' || this.$mq === 'md'
    },
    backgroundImage() {
      if (!this.content) return null

      if (this.isMobile && this.content.backgroundMobile) {
        return this.content.backgroundMobile.fields.file.url
      }

      if (this.content.backgroundDesktop) {
        return this.content.backgroundDesktop.fields.file.url
      }

      return null
    },
    lockupImage() {
      if (!this.content) return null

      if (this.isMobile && this.content.imageTextMobile) {
        return this.content.imageTextMobile.fields.file.url
      }

      if (this.content.imageTextDesktop) {
        return this.content.imageTextDesktop.fields.file.url
      }

      return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 300px;

  @media (min-width: $sm) {
    min-height: 550px;
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 8px;
    background-size: contain;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/6c4Ve31tKBNyjt5Wu8XmGq/4939eff850c8c3cb4e7269b4ae6dc568/pink-gradient-bar.png');

    @media (min-width: 1900px) {
      background-size: cover;
    }
  }

  .hero-container {
    width: 100%;
    max-width: 1600px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:first-of-type {
      width: 52.5%;
      // max-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      margin-top: 25px;
      padding: 20px;

      @media (min-width: $sm) {
        // width: 65%;
        margin-top: 50px;
        padding: 40px;
      }

      img {
        @media (min-width: $sm) {
          max-width: 400px;
        }
      }

      h3 {
        font-size: 32px;
        line-height: 110%;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        color: white;
        filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.5));

        @media (min-width: $md) {
          font-size: 48px;
          
        }
      }
    }

    .images {
      flex: 1;
      position: relative;

      @media (min-width: $sm) {
        flex: unset;
      }

      img:not(.logo) {
        width: auto;
        height: 300px;
        object-fit: cover;
        object-position: left;

        @media (min-width: $sm) {
          height: 550px;
          transform: unset;
        }
      }

      .logo {
        position: absolute;
        bottom: 25px;
        left: -15px;
        width: 110px;
        height: auto;

        @media (min-width: $sm) {
          width: 275px;
          bottom: 60px;
          left: -20px;
        }
        
        @media (min-width: $lg) {
          width: 300px;
          bottom: 50px;
          left: -30px;
        }
      }
    }
  }
}

.careers-hero {
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 260px;

  @media (min-width: $md) {
    height: 670px;
  }

  .hero-container {
    justify-content: center;
    align-items: center;
  }
}

.season-pass-hero, .wsp-hero {
  min-height: 600px;
  align-items: center;
  justify-content: flex-end;

  &.wsp-hero {
    @media (max-width: $sm) {
      min-height: 650px;
    }
  }

  @media (min-width: $sm) {
    align-items: flex-start;
    justify-content: center;
    
  }
  > div:first-of-type {
    img {
      margin: -12px 0;
    }
    .button:not(:hover) {
      color: blue;
    }
  }
}

.hero-alt {
  &::after {
    display: none;
  }
}

.hero-video {
  @apply relative overflow-hidden max-h-screen;

  &:before {
    @apply block w-full;
    content: '';
    padding-top: calc((1 / 1) * 100%);

    @media (min-width: $md) {
      padding-top: calc((9 / 16) * 100%);
    }

  }

  .vue-responsive-videobg {
    @apply absolute top-0 left-0 w-full h-full;
  }
}
</style>
